.footer {
    // background-color: #F2E4CF;
    background-color: #F2E4CF;
    display: flex;
    flex-direction: row;
    color: white;
    height: 8vh;
    padding:0.5%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';

    .footerLeft {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .footerRight {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: end;
        margin-right: 2%;
        font-size: 1em;
        gap:2%;
        color:black;

        .logo {
            height: 100%;
            border-radius: 50%;
            /* Adjust the styles of your logo as needed */
        }

        .copyrightText{
            padding: 0%;
            margin: 0%;
            display: flex;
            align-items: center;
        }
    }
}