.navbar{
    align-self: flex-start;
    height: 10vh;
    width: 100%;
    background-color: #F2E4CF;
    // background-color: rgb(255, 255, 255);
    // background-color: rgb(150, 121, 59);
    
    font-size: 1rem;
    // background-color: #3D562C;
    
    color: black;
    font-weight: bold;
    //font-family: 'Roboto', sans-serif;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';
    



    .containerNav{
        
        display: flex;
        flex-direction: row;
        
        width: 100%;
        height: 100%;


        
        .logo{
            display: flex;

            
            flex:1;
            align-items: center;
            justify-content: start;
            padding-left: 3%;
            
            img{
                border-radius: 50%;
                height: 25%;
                width: 25%;
            }
        }

        .links{
            display: flex;
            flex:6;
            align-items: center;
            justify-content: end;
            
            
            gap: 3%;
            padding-right: 2%;

        }

        .link{
            text-decoration: none;
            color: inherit;
        }

        .auth{
            


            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0% 0% 0% 2%;
            padding:1.5%;


            
            border-left: 3px solid rgb(81, 88, 88);

            background-color: #F2E4CF;
            transition: background-color 0.3s ease; /* Add a smooth transition effect */



            .deauthentification{
                background-color: #F2E4CF;
                display: flex;
                gap:1vw;

                p{
                    margin: 0px;
                }
                button{

                    background-color: #1b1916;
                    font-weight: 600;
                    color: white;   
                    background-color: rgb(81, 88, 88);
                    &:hover{
                        color:black;
                        background-color: #F2E4CF;
                    }
                }

            }

            .authentification {
                .linkRegister,
                .linkLogin {
                  text-decoration: none;
                  color: white;
            
                  h6 {
                    background-color: rgb(106, 106, 106);
                    padding: 0.4rem; /* Adjust padding as needed */
                    margin: 0.5rem; /* Remove margin to ensure full padding effect */
                    overflow: hidden;
                    border-radius: 5%;
                  }
                }


                &:hover{
                    background-color: #F2E4CF;
                
                }
            }

        }

    }
}

@media (max-width: 1200px) {
    .navbar{
        font-size: 0.8rem;
    }
    
    .logo{
        display: flex;
        
        flex:1;
        align-items: center;
        justify-content: start;
        padding-left: 3%;
        margin-right: 5%;;
        
        img{
            border-radius: 50%;
            min-height: 65%;
            min-width: 65%;
        }
    }
}

@media (max-width: 700px) {
    .navbar{
        font-size: 0.8rem;
    }
    
    .logo{
        display: flex;

        
        flex:1;
        align-items: center;
        justify-content: start;
        padding-left: 3%;
        margin-right: 5%;;
        
        img{
            border-radius: 50%;
            min-height: 85%;
            min-width: 85%;
        }
    }
}