.recipesPage{

    min-height: calc(100vh - 10vh);
    background-color: #ece4df; //#ece4df #dac8bc
    padding-bottom: 8%;

    
    .adminDashboard {
        background-color: rgb(250, 84, 84); /* Same color as before */
        padding: 20px; /* Padding to give some space */
        display: flex;
        flex-direction: column;
        gap: 20px; /* Gap between sections */
      }
      
      .adminTitle {
        font-size: 2rem;
        color: white;
        text-align: center;
      }
      
      .functions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      
      .functions > div {
        flex: 1;
        margin-right: 20px;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      }
      
      .functions > div:last-child {
        margin-right: 0;
      }
      
      .functions .deleteRecipe {
        display: flex;
        flex-wrap: wrap;
      }
      
      .functions .deleteRecipe .deleteRecipeForm {
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 10px;
        background-color: #ffcccc;
        border-radius: 5px;
      }
      
      .functions .deleteRecipe .deleteRecipeForm h4 {
        margin-bottom: 5px;
      }
      
      .functions .deleteRecipe .deleteRecipeForm button {
        padding: 5px 10px;
        background-color: #ff0000;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
      .functions .deleteRecipe .deleteRecipeForm button:hover {
        background-color: #cc0000;
      }
      
      .functions .editRecipe {
        display: flex;
        flex-direction: column;
      }
      
      .functions .editRecipe select {
        margin-bottom: 10px;
      }
      
      .functions .editRecipe button {
        padding: 10px 20px;
        background-color: #ff0000;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
      .functions .editRecipe button:hover {
        background-color: #cc0000;
      }
      
    

    .recipesAbout{
        text-align: center;
    }

    .recipes{
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 3% 10% 10% 10%;

        gap: 35vh;
    }


}


@media screen and (max-width: 900px) {
  .recipesPage{
    padding-bottom: 20%;
  }
  .functions > div {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .functions .deleteRecipe,
  .functions .editRecipe {
    flex-direction: column;
  }

  .functions .deleteRecipe .deleteRecipeForm button,
  .functions .editRecipe button {
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  .recipesPage{
    padding-bottom: 40%;
  }
  .functions > div {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .functions .deleteRecipe,
  .functions .editRecipe {
    flex-direction: column;
  }

  .functions .deleteRecipe .deleteRecipeForm button,
  .functions .editRecipe button {
    width: auto;
  }
}