.recipe{

    width: auto;
    height: 50vh;
    
    
    border: 5px solid rgb(125, 15, 7);
    border-radius: 5px;
    // font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman';

    .recipeTopEven{

        display: flex;
        height: 100%;
        flex-direction: row;
        background-color: rgba(107, 22, 22, 0.77);// rgb(5, 56, 6);
        
        .mySwiper{
            flex:1;
            height: 100%;
            width: 100%;

            .swiper-slide img {
                
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .ingredients{
            flex:1;
            

            h2{
                color: beige;
                text-align: center;
            }

            ul{
                color:beige;
                margin: 5%;
            }
            
        }
    }

    .recipeTopOdd{

        display: flex;
        height: 100%;
        flex-direction: row;
        background-color: rgba(107, 22, 22, 0.77);// rgb(5, 56, 6);
        
        .mySwiper{
            flex:1;
            height: 100%;
            width: 100%;

            .swiper-slide img {
                
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .ingredients{
            flex:1;

            h2{
                color: beige;
                text-align: center;
            }

            ul{
                color:beige;
                margin: 5%;
            }
            
        }
    }


    .recipeBottom{
        text-align: center;
        color:rgba(107, 22, 22, 0.77);
        margin-top: 2%;
        padding-bottom: 2%;
        border-bottom: 5px solid rgba(107, 22, 22, 0.77);
        font-family: Georgia, 'Times New Roman', Times, serif;
    }

    
}



@media (max-width: 1400px) {
    .recipe {
      margin: 8% 0%;

      .recipeTopEven,
      .recipeTopOdd {
        .ingredients {
          font-size: 0.9rem;
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .recipe {
      margin: 12% 0%;

      .recipeTopEven,
      .recipeTopOdd {
        .ingredients {
          font-size: 0.8rem;
        }
      }
    }
  }

  @media (max-width: 700px) {
    .recipe {
      margin: 15% 0%;

      .recipeTopEven,
      .recipeTopOdd {
        .ingredients {
          font-size: 0.7rem;
        }
      }
    }
  }
