.aboutPage {
  width: 100%;
  height: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(3, 44, 80);
  gap:5%;

  .aboutMain {
    flex: 1;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    border-radius: 10px;

    border: 3px solid rgb(255, 255, 255);
    background-color: blanchedalmond;
    color: rgb(3, 44, 80);

    text-align: center;
    margin:3%;
    padding: 1%;
    // max-width: 35vw;
    
    // width: 100%;

    img {
      border-radius: 10%;
      overflow: hidden;
      width: 100%;
      height: auto;
    }

    h1 {
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    }

    p {
      padding: 2% 2% 6%;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    }
  }

  .aboutSecondary {
    display: flex;
    flex: 1;
    flex-direction: column;
    // gap: 30vh;
    // gap:15%;

    .aboutme {
      padding: 5%;
      margin: 10%;

      background-color: blanchedalmond;
      color: rgb(3, 44, 80);
      border-radius: 10px;
      border: 5px solid rgb(253, 253, 253);
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);

      text-align: center;
    // width: 100%;

      h2 {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }

      p {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
    }
  }
}


@media (max-width:800px){
    .aboutPage{
        width: 100%;
        height: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(3, 44, 80);

        .aboutMain {
            box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
        
            border: 3px solid rgb(255, 255, 255);
            background-color: blanchedalmond;
            color: rgb(3, 44, 80);
        
            text-align: center;
            margin:5%;
            padding: 2%;
            width: 90%;
    
        }
    }

    
}

