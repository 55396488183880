.login{

    background-color: rgb(250, 230, 190);
    height: 100vh;
    width: 100vw;

    .loginForm{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 7% auto;
        height: auto;
        
        padding:3%;
        width: 30%;

        border-radius: 1%;
        background-color: rgb(93, 117, 94);

        h1{
            text-align: center;
            color: white;
            margin-bottom: 5%;
        }

        label{
            margin-bottom: 1%;
            color:white
        }

        input{
            margin-bottom: 5%;
            padding: 1%;
            border-radius: 5px;
        }

        .buttons{
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap:10%;
            width: 100%;

            button{
                color:black;
                padding: 2%;
                font-weight: 500;
                border-radius: 5px;
                background-color: rgb(250, 230, 190);
                margin-top: 1%;
    
                &:hover{
                    background-color: rgb(124, 106, 67);
                }
            }
        }
        


    }
    .errorMsg{
        margin-top: 5%;  
        padding: 2%;  
        color:red;
        font-weight: bold;
        background-color: rgb(255, 198, 198);
    }
}


