

.qandaPage {
    text-align: center;
  display: flex;
  flex-direction: column;

  .qandaPageTitle {
    text-align: center;

    margin-top: 3%;
    color: rgb(93, 117, 94);
    h1 {
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-size: 5rem;
      text-shadow: 10px 30px 20px rgb(93, 117, 94);
    }
  }

  .qandaPageContent {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 80%;
    gap: 5%;
    margin: 0% 10% 5% 10%;

    .qandaCol1,
    .qandaCol3 {
      display: flex;
      flex: 2;
      gap: 1%;
      flex-direction: column;
      

      .qandaDetails {
        padding: 5%;
        text-align: center;
        color: white;
        // background-color: rgb(150, 121, 59);
        background-color: #bd9355;
      }


      .qandaRow {
        flex: 3;
        color: white;
    
        padding: 5%;
    
        background-color: rgb(93, 117, 94);
        border: 3px solid beige;
        border-radius: 10px;

        .questionAnswer {
            // border: 3px solid rgb(2, 75, 6);
            padding: 8%;
            margin: 3%;
            border-radius: 10px;
          }
      }
      
    }

    .qandaCol2 {
      display: flex;
      margin: 10% 0% 10% 0%;
      flex: 2;

      img {
        object-fit: cover; /* Choose the appropriate value based on your requirements */
        width: 100%;
        height: 100%;
      }
    }
  }


  .adminDashboard {
    background-color: rgb(250, 84, 84); /* Same color as before */
    padding: 20px; /* Padding to give some space */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Gap between sections */
  }
  
  .adminTitle {
    font-size: 2rem;
    color: white;
    text-align: center;
  }
  
  .functions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .functions > div {
    flex: 1;
    margin-right: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .functions > div:last-child {
    margin-right: 0;
  }
  
  .functions .deleteQanda {
    display: flex;
    flex-wrap: wrap;
  }
  
  .functions .deleteQanda .deleteQandaForm {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #ffcccc;
    border-radius: 5px;
  }
  
  .functions .deleteQanda .deleteQandaForm h4 {
    margin-bottom: 5px;
  }
  
  .functions .deleteQanda .deleteQandaForm button {
    padding: 5px 10px;
    background-color: #ff0000;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .functions .deleteQanda .deleteQandaForm button:hover {
    background-color: #cc0000;
  }
  
  .functions .chooseQanda {
    display: flex;
    flex-direction: column;
  }
  
  .functions .chooseQanda select {
    margin-bottom: 10px;
  }
  
  .functions .chooseQanda button {
    padding: 10px 20px;
    background-color: #ff0000;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .functions .chooseQanda button:hover {
    background-color: #cc0000;
  }
  

}

@media (max-width: 900px) {
  .qandaPage {
    display: flex;
    flex-direction: column;
    background-image: url('../../assets/img/bambus_qa.jpg');
    // padding:5%;
    
    padding: 5%;
    
    

    .qandaPageTitle {
        color:white;
        margin-bottom: 5%;;
    }
    .qandaPageContent {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: auto;

      

      .qandaCol1,.qandaCol3 {
        display: flex;
        flex-direction: column;
        margin-bottom: 10%;

        .qandaDetails{
            margin:10% 0%;
            border: 3px solid rgb(255, 255, 255);
            border-radius: 10px;
        }
        
      }

      .qandaCol2{
        display: none;
      }

      
    }
  }

  .functions > div {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .functions .deleteQanda,
  .functions .chooseQanda {
    flex-direction: column;
  }

  .functions .deleteQanda .deleteQandaForm,
  .functions .chooseQanda select,
  .functions .chooseQanda button {
    width: auto;
  }
}
