@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.homePage {
  width: 100%;
  height: 80%;
  background-color: beige;
  opacity: 90%;
  display: flex;
  flex-direction: column;

  .firstPreview {
    display: flex;
    flex-direction: row;

    .motivationalImage {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      flex: 1;
      align-items: center;

      img {
        overflow: hidden;
        display: block;
        object-fit: cover;
        width: 100%;
        height: 80vh;
      }
    }

    .motivationalQuoute {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: #660c0cADD;
      background-color: #660c0c;
      color: white;
    }
  }

  .adminSection{
    background-color: rgb(250, 84, 84);
    padding: 20px; /* Adjust padding as needed */
    width: 100%;
    height: auto; /* Height based on content */
    display: flex;
    flex-direction: row;
    gap: 3%;


    .createBlogPost {
      flex:1;
      background-color: #f0f0f0;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    .createBlogPost p {
      font-weight: bold;
      margin-bottom: 10px;
    }
    
    .fileInputLabel {
      display: block;
      margin-bottom: 10px;
    }
    
    .fileInput {
      display: none;
    }
    
    .submitButton {
      background-color: #007bff;
      color: white;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .submitButton:hover {
      background-color: #0056b3;
    }

    .deleteBlogPost {
      flex: 3;
      display: flex;
      flex-wrap: wrap;
    }
    
    .deleteBlogRow {
      width: 25%; /* Four items per row */
      padding: 10px;
      box-sizing: border-box;
    }
    
    .deleteBlogPostForm {
      position: relative;
    }
    
    .deleteBlogPostForm img {
      max-width: 100%;
      height: auto;
      display: block;
    }
    
    .deleteBlogPostForm button {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      padding: 5px 10px;
      background-color: #ff0000;
      color: #fff;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .deleteBlogPostForm button:hover {
      background-color: #cc0000;
    }
    

  }

  .blogPosts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 7%;
  
    .title {
      text-align: center;
      margin-bottom: 5%;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
      font-size: 355%;
      font-weight: bold;
    }
  
    .posts {
      display: flex;
      flex-direction: row;
      height: 80vh;
      margin: auto;
      width: 80vw;
      gap: 1%;
  
      @media (max-width: 800px) {
        flex-direction: column;
        height: auto;
        gap: 0;
      }
  
      .readScreen {
        width: 100%;
        position: relative;
  
        @media (min-width: 801px) {
          width: 65%;
        }
  
        .mySwiperLeftFull {
          height: 100%;
          width: 100%;
          position: relative;
          overflow: hidden;
  
          .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
  
      .scrollScreen {
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1%;
        position: relative;
        font-size: 36px;
        font-weight: 400;
        border-radius: 5%;
        box-shadow: 1px 0px 50px 10px #0000003c;
        overflow: hidden; /* Hide overflow */
        
        @media (max-width: 800px) {
          width: 100%;
          height: auto;
          gap: 0;
          box-shadow: 1px 0px 44px 7px #660c0c;
        }
      
        .arrow {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 3;
          cursor: pointer;
          color: white;
          background-color: black;
          border-radius: 100%;
      
          &:hover {
            background-color: white;
            color: black;
            font-weight: 800;
          }
        }
      
        .topArrow {
          top: 10px;
        }
      
        .bottomArrow {
          bottom: 10px;
        }
      
        .upper {
          object-fit: cover;
          object-position: top;
          height: 20%;
          width: 100%;
        }
      
        .middle {
          object-fit: cover;
          height: 40%;
          width: 100%;
        }
      
        .bottom {
          object-fit: cover;
          object-position: bottom;
          height: 20%;
          width: 100%;
        }
      
        .upperImageScroll, .bottomImageScroll {
          height: auto;
          width: 80%;
          position: relative;
      
          .bottom, .top {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.2;
          }
        }
      
        .middleImageScroll {
          height: auto;
          width: 80%;
          margin: 2% 0;
      
          .middle {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  

  .qanda {
    min-height: 50vh;
    text-align: center;
    padding: 5%;
    background-color: #660c0c;
    

    h1 {
      margin-bottom: 10px;
      color: rgb(219, 213, 213);
    }

    h2 {
      color: rgb(219, 213, 213);
      margin-bottom: 20px;
    }

    button {
      background-color: #ffffff;
      color: black;
      font-weight: 600;
      padding: 2vh 2vw;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f6cffa;
      }
    }
  }


  .recipes, .about {
      display: flex;
      flex-direction: row;
    .homeImg {
      // background-color: #3dEZ562c;
      background-color: #660c0c;
      display: flex;
      flex: 5;
      flex-direction: column;
      // border:3px solid black;

      justify-content: center;
      align-items: center;
      margin: 3%;
      border-radius: 10%;
      padding: 5.0%;
      

      .imgAndCaption{
        width: 50%;
        height: auto;
        padding: 2%;
        background-color: beige;
        text-align: center;
        img {
          width: 100%;
          display: flex;
          flex: 1;
          // box-shadow: 0 0 50px rgba(250, 255, 159, 0.5);
          // margin: 5%;
          // border: 20px solid beige;
          
          background-color: beige;
          
  
          height: auto;
          object-fit: cover;
        }
        p{
          font-family: 'Brush Script MT', cursive;
          justify-content: center;
          font-size: 2rem;
          margin-top: 5%;
          display: flex;
          flex: 2;
        }
      }
      
    }

    .homeText {
      display: flex;
      flex-direction: column;
      background-color: beige;
      flex: 5;
      font-size: 2em;
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      align-items: center;
      justify-content: center;
      padding: 5%;
      gap:5%;
      text-align: center;

      button {
        background-color: #660c0c;
        color: white;
        font-weight: 600;
        padding: 2vh 2vw;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #470404;
        }
      }

    
    }
  }
}

@media (max-width: 768px) {
  .firstPreview {
    flex-direction: column-reverse; // Reversed for smaller screens
  }

  .blogPosts {
    margin: 0; // Removed margin for smaller screens
    .posts {
      flex-direction: column; // Adjusted for smaller screens
      height: auto; // Adjusted for smaller screens

      .scrollScreen{
        margin-top: 5%;

        .upperImageScroll, .bottomImageScroll{
          
          img{
            height: 150px !important;
          }
          
        }
      }

      
    }
  }

  .recipes,
  .about {
    flex-direction: column; // Adjusted for smaller screens
    margin: 0; // Removed margin for smaller screens

    .homeImg,
    .homeText {
      margin: 0; // Removed margin for smaller screens
    }
  }
}

